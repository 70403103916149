import AuthService from '../../../services/authService'
import { 
   checkAuthSuccess, 
   checkAuthFail, 
   logoutSuccess, 
   managerLoginSuccess, 
   updateFollowUpDateEnabledSuccess,
   updateHighlightUnansweredDialogsSuccess
} from '.'

export const checkAuth = () => async (dispatch) => {
   try {
      const data = await AuthService.fetchUser()

      if (!data.error) {
         dispatch(checkAuthSuccess(data.data))
      } else {
         const appLink = process.env.REACT_APP_AUTH_LINK
         const clientId = process.env.REACT_APP_CLIENT_ID
         dispatch(logoutSuccess())
         dispatch(checkAuthFail(`${appLink}?client_id=${clientId}`))
      }
   } catch (err) {
      console.log(err)
   }
}

export const managerLogin = (authData) => async (dispatch) => {
   try {
      const { data } = await AuthService.login(authData)

      if (!data.error) {
         dispatch(managerLoginSuccess())
      } else {
         return data.error
      }
   } catch (err) {
      if (typeof err.response === 'undefined') {
         dispatch(managerLoginSuccess())
      } else {
         alert('Произошла ошибка...')
      }
   }
}

export const logout = () => async (dispatch) => {
   try {
      const { data } = await AuthService.logout()

      if (!data.error) {
         dispatch(logoutSuccess())
         window.location.reload(false)
      } else {
         alert(data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const requestResetPassword = (body) => async (dispatch) => {
   try {
      const data = await AuthService.requestResetPassword(body)
      
      if (data.error && data.data.error === 'Manager not found.') {
         return Promise.reject('Менеджер с такой почтой не найден. Проверьте правильность почты!')
      } else if (data.error) {
         return Promise.reject(data.data.error)
      }
   } catch (err) {
      alert('Не удалось отправить запрос на восстановление пароля!')
   }
}

export const resetPassword = (body) => async (dispatch) => {
   try {
      const data = await AuthService.resetPassword(body)

      if (data.error && data.data.error === 'Incorrect token or email.') {
         return Promise.reject('Неправильный email или ссылка на восстановление пароля неактивна.')
      } else if (data.error && data.data.error === 'Error to reset password, try later.') {
         return Promise.reject('Не удалось восстановить пароль, попробуйте позже.')
      }
   } catch (err) {
      return Promise.reject('Не удалось восстановить пароль!')
   }
}

export const updateFollowUpDateEnabled = (body) => async (dispatch) => {
   try {
      const data = await AuthService.setFollowUpDateEnabled(body)

      if(!data.error) {
         dispatch(updateFollowUpDateEnabledSuccess(body.followUpDateEnabled))
      }
   } catch (err) {
      return alert('Не удалось обновить настройку "Присвоение даты контакта"!')
   }
}

export const updateHighlightUnansweredDialogs = (time) => async (dispatch) => {
   try {
      const body = { timeWithoutAnswer: time }
      body.highlightUnansweredDialogues = time !== undefined ? true : false

      const data = await AuthService.setHighlightUnansweredDialogs(body)

      if(!data.error) {
         dispatch(updateHighlightUnansweredDialogsSuccess(body))
      }
   } catch (err) {
      return alert('Не удалось обновить настройку "Отметка диалогов, в которых нет ответа менеджера"!')
   }
}