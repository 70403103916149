import React from 'react'

import './Modal.css'

const Modal = ({ show, title = '', showCloseBtn = true, onClose = () => {}, children}) => {
   return (
      <div className={`modal ${show ? 'active' : ''}`}>
         <div className='modal__body'>
            <div className='modal__header'>
               {title && <p className='modal__title'>{title}</p>}
               {showCloseBtn && (
                  <button 
                     className='modal__close-btn' 
                     onClick={onClose}
                  >
                     <img src="/assets/controls/close.svg" alt="Close" />
                  </button>
               )}
            </div>
            {children}
         </div>
      </div>
   )
}

export default Modal