import { createSlice } from "@reduxjs/toolkit"
import { DEFAULT_LIMIT } from "../../../utils/consts/defaultContactsParams"

const initialState = {
   limit: DEFAULT_LIMIT,
   cursor: null,
   searchValue: '',
   channel: null,
   channelIds: []
}

const contactsFilterSlice = createSlice({
   name: 'contactsFilter',
   initialState,
   reducers: {
      setContactsFilterCursor: (state, action) => {
         state.cursor = action.payload
      },
      setContactsFilterSearchValue: (state, action) => {
         state.searchValue = action.payload
         state.cursor = ''
      },
      setContactsFilterChannel: (state, action) => {
         state.channel = action.payload
         state.cursor = ''
      },
      setContactsFilterChannelId: (state, action) => {
         state.cursor = ''
         if (action.payload === null) {
            state.channelIds = []
            return
         }
         state.channelIds = [action.payload]
      },
      setContactsFilterChannelIds: (state, action) => {
         state.cursor = ''
         
         const id = action.payload
         if (id === null) {
            state.channelIds = []
            return
         }
        
         state.channelIds = state.channelIds.includes(id)
            ? state.channelIds.filter(channelId => channelId !== id)
            : [...state.channelIds, id]
      },
      setContactsFilter: (state, action) => {
         return { 
            ...state,
            ...action.payload 
         }
      }
   }
})

export const {
   setContactsFilterCursor,
   setContactsFilterSearchValue,
   setContactsFilterChannel,
   setContactsFilter,
   setContactsFilterChannelId,
   setContactsFilterChannelIds
} = contactsFilterSlice.actions
export default contactsFilterSlice.reducer