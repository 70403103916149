import React from 'react'

import './Loader.css'

const Loader = ({ className = '' }) => {
   return (
      <div className={`loader-container ${className}`}>
         <div className="loader">
            <div className="element element1"></div>
            <div className="element element2"></div>
            <div className="element element3"></div>
            <div className="element element4"></div>
         </div>
      </div>
   )
}

export default Loader