import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash.debounce'
import '../settings/dropdown.css'

import { contacts_selectContacts, contacts_selectContactsLoading } from '../../store/slices/contacts/selectors'
import { contactsFilter_selectFilter } from '../../store/slices/contactsFilter/selectors'
import { 
   setContactsFilter, 
   setContactsFilterChannelId, 
   setContactsFilterSearchValue 
} from '../../store/slices/contactsFilter'
import { fetchContacts } from '../../store/slices/contacts/thunk-creators'
import { 
   mapContactsFilterToQuery, 
   mapContactsFilterToSearch, 
   mapContactsSearchToFilter, 
   mapContactsSearchToQuery
} from '../../utils/normalizeContactsParams'

import AddContact from '../modals/AddContact'
import SidebarContactCard from './SidebarContactCard'
import { Button } from '../Button'
import Loader from '../Loader'

const SidebarContactsList = ({
   toggleSidebarView,
   showAddContactModal,
   setShowAddContactModal,
   channelList,
   newContactInitiationParams,
   allowedChannel,
   selectedContact,
   isManager,
   handleContactCardClick
}) => {
   const contacts = useSelector(contacts_selectContacts)
   const contactsLoading = useSelector(contacts_selectContactsLoading)
   const contactsFilter = useSelector(contactsFilter_selectFilter)
   const contactsSearchValue = contactsFilter.searchValue
   const contactsChannel = contactsFilter.channel
   const contactsChannelIds = contactsFilter.channelIds

   const [searchValue, setSearchValue] = useState(contactsSearchValue)
   const [shouldUpdateQuery, setShouldUpdateQuery] = useState(false)
   const [shouldFetchContacts, setShouldFetchContacts] = useState(false)
   const [isShowDropdown, setIsShowDropdown] = useState(false)
   const [disableContactsScroll, setDisableContactsScroll] = useState(false)

   const dispatch = useDispatch()
   const location = useLocation()
   const navigate = useNavigate()

   const onSearchChange = useMemo(() => debounce((searchValue) => {
      dispatch(setContactsFilterSearchValue(searchValue))
   }, 500), [])

   const handleSearchValueChange = (e) => {
      const value = e.target.value
      setSearchValue(value)
      onSearchChange(value)
   }

   const handleToggleDropdown = () => {
      setIsShowDropdown(!isShowDropdown)
   }

   const onChannelChange = useMemo(() => debounce((value) => {
      dispatch(setContactsFilterChannelId(value))
   }, 300), [])

   const handleChannelChange = (type) => {
      onChannelChange(type)
      setIsShowDropdown(false)
   }

   const handleFetchContacts = async (query) => {
      await dispatch(fetchContacts(query))
   }

   const handleContactsScroll = async (e) => {
      if (
         e.target.scrollHeight - e.target.scrollTop <=
         e.target.clientHeight + 35 && !disableContactsScroll 
      ) {
         setDisableContactsScroll(true)
         const query = mapContactsFilterToQuery(contactsFilter) 
         await dispatch(fetchContacts(query, true))
         setDisableContactsScroll(false)
      }
   }

   useEffect(() => {
      const query = mapContactsSearchToQuery(location.search)
      const currentFilter = mapContactsSearchToFilter(location.search)
      dispatch(setContactsFilter(currentFilter))
      if (!contacts.length) {
         handleFetchContacts(query)
      }
   }, [])

   useEffect(() => {
      if (shouldUpdateQuery) {
         const search = mapContactsFilterToSearch(contactsFilter)
         navigate(search)
         setShouldFetchContacts(true)
      } else {
         setShouldUpdateQuery(true)
      }
   }, [contactsFilter])

   useEffect(() => {
      if (shouldFetchContacts) {
         const query = mapContactsFilterToQuery(contactsFilter)
         handleFetchContacts(query)
      }
   }, [contactsSearchValue, contactsChannel, contactsChannelIds])

   useEffect(() => setSearchValue(contactsSearchValue), [contactsSearchValue])

   return (
      <>
         <div style={{ padding: '0 36px 0 36px', marginTop: '36px' }}>
            <div
               className="sidebar-header-search-field"
               style={{ marginBottom: '20px' }}
            >
               <img
                  className="sidebar-header-search-field-icon"
                  src={`/assets/sidebar/search.svg`}
                  alt="Lookout"
               />
               <input
                  className="sidebar-header-search-field-input"
                  type="text"
                  placeholder="Поиск"
                  value={searchValue}
                  onChange={handleSearchValueChange}
               />
            </div>
         </div>
         <div style={{ padding: '0 36px 0 36px', marginBottom: '22px' }}>
            <div className="dropdown">
               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                  <div
                     className="dropdown-current-item"
                     onClick={handleToggleDropdown}
                  >
                     <span className="dropdown-current-item_name">
                        Только выбранные
                     </span>
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        {isShowDropdown ? (
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                              fill="#252525"
                           />
                        ) : (
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                              fill="#252525"
                           />
                        )}
                     </svg>
                  </div>
                  {isShowDropdown && (
                     <div
                        className="dropdown-list"
                        style={{ zIndex: '9999', width: '328px' }}
                     >
                        <div
                           className="dropdown-list_wrapper"
                           style={{ padding: '20px 24px' }}
                        >
                           {
                              <ul className="dropdown-list_items">
                                 <li
                                    className="dropdown-list_item"
                                    style={{
                                       margin: '0',
                                       marginBottom: '16px',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => handleChannelChange(null)}
                                 >
                                    <div className="dropdown-list_item-block">
                                       Все каналы
                                    </div>
                                 </li>
                                 {channelList.map((item) => {
                                    return (
                                       <li
                                          className="dropdown-list_item"
                                          style={{
                                             margin: '0',
                                             marginBottom: '16px',
                                             cursor: 'pointer',
                                          }}
                                          key={item.id}
                                          onClick={() => handleChannelChange(item.id)}
                                       >
                                          <div className="dropdown-list_item-block">
                                             {item.type + ' - ' + item.username}
                                          </div>
                                       </li>
                                    )
                                 })}
                              </ul>
                           }
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         <div
            className="sidebar-list"
            onScroll={handleContactsScroll}
            style={{
               height: 'calc(68vh - 80px)',
               display: toggleSidebarView ? 'flex' : 'none',
            }}
         >
            <AddContact
               close={() => {
                  setShowAddContactModal(!showAddContactModal)
               }}
               isVisible={showAddContactModal}
               channelList={channelList.filter(
                  (channel) => channel.status === 'active'
               )}
               newContactInitiationParams={newContactInitiationParams}
               allowedChannel={allowedChannel}
            />
            {contactsLoading
               ? <Loader />
               : contacts.map((contact, index) => (
                  <SidebarContactCard
                     selectedContact={selectedContact}
                     isManager={isManager}
                     key={index}
                     cardInfo={contact}
                     onCardClick={handleContactCardClick}
                  />
               )
            )}
            <div className="sidebar-list-add-button-block">
               <Button
                  text='Добавить контакт'
                  onClick={() => setShowAddContactModal(true)}
               />
            </div>
         </div>
      </>
   )
}

export default SidebarContactsList
