import React, { useState } from 'react'
import axios from 'axios'
import './dialogSettings.css'
import ReturnBtn from '../mainbar/ReturnBtn'
import { useDispatch, useSelector } from 'react-redux'
import { auth_selectIsManager, auth_selectUser } from '../../store/slices/auth/selectors'
import { 
   updateFollowUpDateEnabled, 
   updateHighlightUnansweredDialogs 
} from '../../store/slices/auth/thunk-creators'
import { btnSizes, Button } from '../Button'
import TimeWithoutAnswerModal from '../modals/TimeWithoutAnswerModal'

const DialogSettings = ({
   autoReadStatus,
   setAutoReadStatus,
   isMainbarTablet,
   handleReturnBtnClick
}) => {
   const user = useSelector(auth_selectUser)
   const isManager = useSelector(auth_selectIsManager)
   const followUpDateEnabled = user.follow_up_date_enabled
   const timeWithoutAnswer = user.time_without_answer
   const highlightUnansweredDialogs = Boolean(user.highlight_unanswered_dialogues)

   const [autoReadToggle, setAutoReadToggle] = useState(autoReadStatus)
   const [disableFollowUpDateToggle, setDisableFollowUpDateToggle] = useState(false)
   const [disableHighlightUnansweredDialogsToggle, setDisableHighlightUnansweredDialogsToggle] = useState(false)
   const [showTimeWithoutAnswerModal, setShowTimeWithoutAnswerModal] = useState(false)

   const dispatch = useDispatch()

   const handleAutoReadToggle = () => {
      setAutoReadToggle((prev) => !prev)
      let queryString = autoReadStatus ? 'false' : 'true'
      axios({
         method: 'post',
         url: `/api/v1/dialog/auto-read?status=${queryString}`,
         withCredentials: true,
      })
         .then(function (response) {
            //handle success
            if (response.data.error === false) {
               setAutoReadStatus(!autoReadStatus)
            } else {
               window.alert('Произошла ошибка!')
               setAutoReadToggle((prev) => !prev)
            }
         })
         .catch(function (response) {
            //handle error
            console.log(response)
            window.alert('Произошла ошибка!')
            setAutoReadToggle((prev) => !prev)
         })
   }

   const handleFollowUpDateToggle = async () => {
      setDisableFollowUpDateToggle(true)
      await dispatch(updateFollowUpDateEnabled({
         followUpDateEnabled: !followUpDateEnabled
      }))
      setDisableFollowUpDateToggle(false)
   }

   const handleToggleTimeWithoutAnswerModal = () => setShowTimeWithoutAnswerModal(show => !show)

   const handleHighlightUnansweredDialogsToggle = async () => {
      if (!highlightUnansweredDialogs) {
         handleToggleTimeWithoutAnswerModal()
         return
      }

      setDisableHighlightUnansweredDialogsToggle(true)
      await dispatch(updateHighlightUnansweredDialogs())
      setDisableHighlightUnansweredDialogsToggle(false)
   }

   const handleTimeWithoutAnswerModalSubmit = async (time) => {
      await dispatch(updateHighlightUnansweredDialogs(time))
      setShowTimeWithoutAnswerModal(false)
   }

   return (
      <div className="dialog-settings">
         <div className="dialog-settings-wrapper">
            <ReturnBtn
               isMainbarTablet={isMainbarTablet}
               onClick={handleReturnBtnClick}
            />
            <h2 className='dialog-settings__title'>
               Настройки диалога
            </h2>
            <ul className='dialog-settings__settings-list'>
               <li className='dialog-settings__settings-list-item'>
                  <div className='setting-item setting-item_auto-read'>
                     <div
                        className={`setting-item__controller manager-permission-controller ${
                           !autoReadToggle 
                              ? 'setting-item__controller-toggle-btn_active' 
                              : ''
                        }`}
                        onClick={handleAutoReadToggle}
                     >
                        <div
                           className="setting-item__controller-toggle-btn manager-permission-toggle-button"
                        ></div>
                     </div>
                     <div className='setting-item__controller-info'>
                        <h4 className='setting-item__controller-name'>
                           Оставлять сообщения непрочитанными
                        </h4>
                        <p className='setting-item__controller-description'>
                           Это удобно в тех случаях, когда вы хотите вернуться к сообщению позже. Сообщение со статусом "непрочитанно" останется сверху в списке диалогов
                        </p>
                     </div>
                  </div>
               </li>
               {!isManager && (
                  <>
                     <li className='dialog-settings__settings-list-item'>
                        <div className='setting-item'>
                           <button
                              className={`setting-item__controller manager-permission-controller ${
                                 followUpDateEnabled 
                                    ? 'setting-item__controller-toggle-btn_active' 
                                    : ''
                              }`}
                              onClick={handleFollowUpDateToggle}
                              disabled={disableFollowUpDateToggle}
                           >
                              <div
                                 className="setting-item__controller-toggle-btn manager-permission-toggle-button"
                              ></div>
                           </button>
                           <div className='setting-item__controller-info'>
                              <h4 className='setting-item__controller-name'>
                                 Присвоение даты контакта
                              </h4>
                           </div>
                        </div>
                     </li>
                     <li className='dialog-settings__settings-list-item'>
                        <div className='time-without-answer-setting'>
                           <p className='time-without-answer-setting__title'>Отметка диалогов без ответа менеджера</p>
                           <div className='time-without-answer-setting__group'>
                              <div className='setting-item'>
                                 <button
                                    className={`setting-item__controller manager-permission-controller ${
                                       highlightUnansweredDialogs 
                                          ? 'setting-item__controller-toggle-btn_active' 
                                          : ''
                                    }`}
                                    onClick={handleHighlightUnansweredDialogsToggle}
                                    disabled={disableHighlightUnansweredDialogsToggle}
                                 >
                                    <div
                                       className="setting-item__controller-toggle-btn manager-permission-toggle-button"
                                    ></div>
                                 </button>
                                 <div className='setting-item__controller-info'>
                                    <h4 className='setting-item__controller-name'>
                                       Отметка диалогов, в которых нет ответа менеджера
                                    </h4>
                                 </div>
                                 <Button
                                    customStyle='time-without-answer-setting__btn'
                                    size={btnSizes.SMALL}
                                    onClick={handleToggleTimeWithoutAnswerModal}
                                 >
                                    <img src='/assets/controls/setting-icon.svg' alt='Setting' />
                                 </Button>
                              </div>

                           </div>
                        </div>
                     </li>
                  </>
               )}
            </ul>
         </div>
         <TimeWithoutAnswerModal 
            show={showTimeWithoutAnswerModal}
            timeWitoutAnswer={timeWithoutAnswer} 
            onClose={handleToggleTimeWithoutAnswerModal}
            onSubmit={handleTimeWithoutAnswerModalSubmit}
         />
      </div>
   )
}

export default DialogSettings
