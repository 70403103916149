import React, { useEffect, useState } from 'react'

import './TimeWithoutAnswerModal.css'

import Modal from '../Modal'
import { Button } from '../../Button'
import NumberInput from '../../NumberInput'
import RangeInput from '../../RangeInput'

const TimeWithoutAnswerModal = ({ timeWitoutAnswer, show, onClose, onSubmit }) => {
   const [time, setTime] = useState(Math.floor(timeWitoutAnswer / 60) || 10)
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
   
   const handleTimeChange = (value) => setTime(value)

   const handleSubmit = async () => {
      if (disableSubmitBtn) return
      setDisableSubmitBtn(true)
      await onSubmit(time * 60)
      setTimeout(() => setDisableSubmitBtn(false), 600)
   }

   useEffect(() => {
      setTime(Math.floor(timeWitoutAnswer / 60) || 10)
   }, [timeWitoutAnswer])

   return (
      <Modal
         show={show}
         title='Настройка таймера отметки'
         onClose={onClose}
      >
         <div className='time-without-answer-form'>
            <div className='time-without-answer-form__control'>
               <NumberInput 
                  name='Таймер'
                  value={time}
                  min={1}
                  max={120}
                  icon='/assets/controls/timer-icon.svg'
                  mesurementUnit='Минут'
                  onChange={handleTimeChange}
               />
               <RangeInput
                  value={time}
                  min={1}
                  max={120}
                  onChange={handleTimeChange}
               />
            </div>
            <Button
               text='Сохранить настройку'
               disabled={disableSubmitBtn}
               onClick={handleSubmit}
            />
         </div>
      </Modal>
   )
}

export default TimeWithoutAnswerModal