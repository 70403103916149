export const getMinuteWordInAccusative = (minutes) => {
    const lastDigit = minutes % 10
    const lastTwoDigits = minutes % 100

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return "минуту"
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
        return "минуты"
    } else {
        return "минут"
    }
}

export const getHourWord = (minutes) => {
    const lastDigit = minutes % 10
    const lastTwoDigits = minutes % 100

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return "час"
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
        return "часа"
    } else {
        return "часов"
    }
}

export const getTimeDescription = (minutes) => {    
    if (minutes >= 14400) {
        return "Более 10 дней"
    } else if (minutes >= 1440 && minutes < 14400) {
        const days = Math.floor(minutes / 1440)
        const dayWord = days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
        return `${days} ${dayWord}`
    } else if (minutes > 59) {
        const hours = Math.floor(minutes / 60);
        return `${hours} ${getHourWord(hours)}`
    } else {
        return `${minutes} ${getMinuteWordInAccusative(minutes)}`
    }
}
