import React from 'react'

import './DialogAnswerTimer.css'

import useWithoutAnswerTimer from '../../hooks/useWithoutAnswerTimer'
import { getTimeDescription } from './utils'

const DialogAnswerTimer = ({ customStyle = '', unansweredMessageTimestamp, maxTimeWithoutAnswer }) => {
   const [minutesWithoutAnswer, isOverdue] = useWithoutAnswerTimer(unansweredMessageTimestamp, maxTimeWithoutAnswer)

   const text = getTimeDescription(minutesWithoutAnswer)
   
   return (
      <div className={`dialog-answer-timer ${isOverdue ? 'dialog-answer-timer_overdue' : ''} ${customStyle}`}>
         <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.12833 1.83594V0.835938H7.872V1.83594H4.12833ZM5.50016 9.20777H6.50016V5.4641H5.50016V9.20777ZM6.00016 14.3359C5.22072 14.3359 4.48738 14.1872 3.80016 13.8898C3.11294 13.5923 2.51211 13.1863 1.99766 12.6718C1.48311 12.1573 1.07711 11.5565 0.779663 10.8693C0.482218 10.182 0.333496 9.44872 0.333496 8.66927C0.333496 7.88983 0.482218 7.15649 0.779663 6.46927C1.07711 5.78205 1.48311 5.18122 1.99766 4.66677C2.51211 4.15222 3.11294 3.74622 3.80016 3.44877C4.48738 3.15133 5.22072 3.0026 6.00016 3.0026C6.66772 3.0026 7.31066 3.11588 7.929 3.34244C8.54733 3.56888 9.1215 3.89322 9.6515 4.31544L10.4822 3.48477L11.1847 4.18727L10.354 5.01794C10.7762 5.54794 11.1006 6.1221 11.327 6.74044C11.5536 7.35877 11.6668 8.00172 11.6668 8.66927C11.6668 9.44872 11.5181 10.182 11.2207 10.8693C10.9232 11.5565 10.5172 12.1573 10.0027 12.6718C9.48822 13.1863 8.88738 13.5923 8.20016 13.8898C7.51294 14.1872 6.77961 14.3359 6.00016 14.3359ZM6.00016 13.3359C7.28905 13.3359 8.38905 12.8804 9.30016 11.9693C10.2113 11.0582 10.6668 9.95816 10.6668 8.66927C10.6668 7.38038 10.2113 6.28038 9.30016 5.36927C8.38905 4.45816 7.28905 4.0026 6.00016 4.0026C4.71127 4.0026 3.61127 4.45816 2.70016 5.36927C1.78905 6.28038 1.3335 7.38038 1.3335 8.66927C1.3335 9.95816 1.78905 11.0582 2.70016 11.9693C3.61127 12.8804 4.71127 13.3359 6.00016 13.3359Z" fill="#8C9497"/>
         </svg>
         Ожидает {text}
      </div>
   )
}

export default DialogAnswerTimer