import React from 'react'

import './NumberInput.css'

const NumberInput = ({ 
   icon = '', 
   name = '', 
   min = 0, 
   max = 10, 
   value, 
   mesurementUnit = '', 
   onChange 
}) => {
   const handleChange = (e) => {
      const value = e.target.value
      const trimmedValue = value.replace(/[^0-9]/g, '')
      const numericValue = Number(trimmedValue)
  
      if (trimmedValue === '' || numericValue < min || numericValue > max) {
         return
      }
  
      onChange(trimmedValue)
   }

   return (
      <div className='number-input'>
         <div className='number-input__container'>
            {icon && (
               <img 
                  className='number-input__icon' 
                  src={icon} 
                  alt='' 
               />
            )}
            {name && (
               <span className='number-input__name'>
                  {name}
               </span>
            )}
         </div>
         <div className='number-input__container'>
            <input
               className='number-input__input'
               type='text' 
               value={value}
               onChange={handleChange}
            />
            {mesurementUnit && (
               <span className='number-input__mesurement-unit'>
                  {mesurementUnit}
               </span>
            )}
         </div>
      </div>
   )
}

export default NumberInput